import { observable } from "mobx";
import { Subject } from "rxjs";
import { Routes, RoutesMenuItem } from "../routes";

export class MainMenuService
{
    @observable
    shown = true;

    @observable
    path = "";

    allroutes:RoutesMenuItem[] = [];
    routesMappedParent:Map<string,RoutesMenuItem> = new Map<string,RoutesMenuItem>();

    changeRoute:Subject<string> = new Subject<string>();
    constructor() {
        //window.addEventListener('resize', this.handleResize);
        this.path = window.location.pathname
      }
    
    //   handleResize = () => {
    //     console.log('DETECT');
    //   }
    

    switch()
    {
        this.shown = !this.shown;
    }

    setActivePath(path?:string)
    {
        if(path)
        {
            this.path = path;
            this.changeRoute.next(this.path);
        }
        
    }

    setAllRoutes(allroutes:RoutesMenuItem[])
    {
        this.allroutes = allroutes;
        this.allroutes.forEach(route => {
            if (route.subMenuItems) {
                this.routesMappedParent.set(route.path, route);
              route.subMenuItems.forEach(subMenuItem => {
                if(subMenuItem.subMenuPath)
                {
                     this.routesMappedParent.set(subMenuItem.subMenuPath, route);
                }
              });
            } else {
              if(!this.routesMappedParent.get(route.path))
              {
                 this.routesMappedParent.set(route.path, route);
              }
             
            }
          });
    }

    getActiveSubItem(parentItem:RoutesMenuItem,path:string):RouteInfo
    {
      if(parentItem.subMenuItems!=undefined)
      {

        if(parentItem.subMenuItems.length>0&&path!=parentItem.path)
        {
          var found = parentItem.subMenuItems.find((x)=>{
            return x.subMenuPath==path;
          });
          if(found)
          {
              return {path:(found.subMenuPath)?found.subMenuPath:"", parentPath:parentItem.path, title:(found.title)?found.title:""};
          }
        
        }
        else
        {
          return {path:parentItem.path, parentPath:parentItem.path, title:(parentItem.title)?parentItem.title:""}
        }

      }
        return {path:parentItem.path, parentPath:parentItem.path, title:(parentItem.title)?parentItem.title:""}
    }
}

export interface RouteInfo
{
  path:string;
  parentPath:string;
  title:string;
}