import { BehaviorSubject } from "rxjs";

const wpLabsNotifChanged = new BehaviorSubject<any[]>([]);
export const wpLabsNotifsSvc = {
    changewpLabsNotifs: (notifs) => wpLabsNotifChanged.next(notifs),
    wpLabsNotifObservable: () => wpLabsNotifChanged.asObservable()
};

const wpLabsHistNotifsChanged = new BehaviorSubject<any[]>([]);

export const wpLabsHistNotifsSvc = {
    changeWpLabsHistifs: (notifs) => wpLabsHistNotifsChanged.next(notifs),
    wpLabsHistNotifObservable: () => wpLabsHistNotifsChanged.asObservable()
};