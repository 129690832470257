import { Layout } from "antd";
import React from "react";
import "./app-header-sidebar.css";
import logoImage from './logo.jpg';
import logoImageSVG from './logo.svg';
export type AppHeaderSidebarProps = {};
class AppHeaderSideBar extends React.Component<AppHeaderSidebarProps>
{
    constructor(props: AppHeaderSidebarProps)
    {
        super(props);
    }
    render()
    {
        return (
        <Layout className={'top-bar-sidebar'}>
            <div className="nav-sidebar-brand">
                <img src={logoImageSVG} alt="CRM4" />
            </div>
            
        </Layout>
        )
    }
}
export default AppHeaderSideBar;