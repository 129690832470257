

import { observer} from 'mobx-react';
import React from 'react';
import { Row, Col, Icon, Dropdown, Button, Layout, Menu, Badge } from 'antd';
import { Services } from '../../helpers/static-services';
import { Link } from 'react-router-dom';
import { observable } from 'mobx';
import "./app-header.css";
import { Routes, RoutesMenuItem } from '../../routes';
import { RouteInfo } from '../../services/main-menu-service';
import { NotificationCountProvider } from '../ProtectedRoute/ProtectedRoute';
import { AuthenticationService } from '../../services/authenticationService';

export type AppHeaderProps = {title?: string, showCollapseButton?: boolean};

export const MenuSwitch = observer((props: {onSwitch: () => void, state: () => boolean}) => 
  <Icon className="trigger" type={!props.state() ? 'menu-unfold' : 'menu-fold'} onClick={props.onSwitch} />);

@observer
class AppHeader extends React.Component<AppHeaderProps>
{
    accessService = Services.accessService;
    
    mainMenuService = Services.mainMenuService;
    
    state = {
        path: window.location.pathname,
        activeRoute: {},
        activeRouteInfo:{}
      };
    
    activeRoute = {
       route: null
    } ;

    constructor(props: AppHeaderProps)
    {
        super(props);
        this.mainMenuService.changeRoute.subscribe((l)=>{
            var route = this.mainMenuService.routesMappedParent.get(l);
            if(route)
            {
                this.setState({ path: l, activeRoute:route, activeRouteInfo:this.mainMenuService.getActiveSubItem(route,window.location.pathname) });
            }
            
        })
    }

    
    public routeHooker:any;
    routes = new Routes().getRoutes();
    notificationsNodes:RoutesMenuItem[] = [];
    authenticationService = new AuthenticationService();
    componentDidMount() {
        window.addEventListener('popstate', this.handlePopstate);
        this.mainMenuService.setAllRoutes(this.routes);

        
        
        var notificationParent = this.mainMenuService.allroutes.find((k)=>{
            return k.title=="Notifications";
        })
        
        if(notificationParent && notificationParent.subMenuItems)
        {
            this.notificationsNodes = notificationParent.subMenuItems.filter((l)=>{
                return l.showInMenu
            })
        }

        var route = this.mainMenuService.routesMappedParent.get(window.location.pathname);

        if(route)
        {
            this.setState({ path: window.location.pathname, activeRoute:route, activeRouteInfo:this.mainMenuService.getActiveSubItem(route,window.location.pathname) });
        }

        this.routeHooker = setInterval(()=>{

            var route = this.mainMenuService.routesMappedParent.get(window.location.pathname);

            if(route)
            {
                this.setState({ path: window.location.pathname, activeRoute:route, activeRouteInfo:this.mainMenuService.getActiveSubItem(route,window.location.pathname) });
            }

        },100)
      }
    
      componentWillUnmount() {
        window.removeEventListener('popstate', this.handlePopstate);
        clearInterval(this.routeHooker);
      }
    

      userDropdownMenu() {
        return (
          <Menu>
            <Menu.Item key="2">
              <Link to="/logout">
                <Icon type="logout" />
                <span style={{ marginLeft: "10px" }}>Logout</span>
              </Link>
            </Menu.Item>
          </Menu>
        );
      }

      userDivContainerMenu() {
        return (
          <div className='profileDropdown'>
            <h3>{this.authenticationService.getUsername()}</h3>
            <div className="profile-menu-item">
              <Link to="/settings">
                <Icon type="setting" />
                <span style={{ marginLeft: "10px" }}>Settings</span>
              </Link>
            </div>
            <div className="profile-menu-item">
              <Link to="/logout">
                <Icon type="logout" />
                <span style={{ marginLeft: "10px" }}>Logout</span>
              </Link>
            </div>
            </div>
        );
      }


      handlePopstate = () => {
          console.log('"USED HISTORY');
          this.setState({ path: window.location.pathname });
      };

    render()
    {
        return (
            <Layout.Header  className={'top-bar'}>
               
                

                <Row className={'header-container'}>
                    {this.props.showCollapseButton &&<Col style={{ textAlign: 'left' }} span={1}>
                        <MenuSwitch state={() => this.mainMenuService.shown} onSwitch={this.mainMenuService.switch.bind(this.mainMenuService)}></MenuSwitch>
                    </Col>}

                    <Col span={16}>


                    {
                        ((this.state.activeRouteInfo as RouteInfo).parentPath !== window.location.pathname) && (
                            <Link to={(this.state.activeRouteInfo as RouteInfo).parentPath}>
                                <div className={'circle'}><Icon style={{ fontSize: '20px',position:"relative",top:'-17px', left:'3px' }} type="left" /></div>
                            </Link>
    
                        )
                    }

                    <h2>
                    {(this.state.activeRouteInfo as RouteInfo).title}
                    </h2>
                        {this.props.children}
                    </Col>

                    <Col span={5} style={{ float: 'right', textAlign: 'right', marginRight: '15px'}}>
                        {
                           this.notificationsNodes.map((route)=>{
                            return (
                            
                            <div>
                             <NotificationCountProvider
                                  key={`${route.subMenuPath}-notification-count-provider`}
                                >
                                {(notificationCount) => (
                                    
                                        
                                        <Link
                                        to={route.subMenuPath}><div style={{position:"relative"}} className={'circle'}>
                                            <Icon style={{ fontSize: '20px',position:"relative",top:'-17px', left:'-3px' }} type={route.icon} />
                                            <Badge
                                            offset={[7, -7]}
                                            count={
                                            route.name === "wpLabs"
                                                ? notificationCount.wpLabsNotifsCnt
                                                : route.name ===
                                                "listingsnotif"
                                                ? notificationCount.notifsCnt
                                                : 0
                                            }
                                            key={`${route.subMenuPath}-badge`}
                                        >

                                        </Badge></div>
                                    </Link>
                                  
                                )}
                                </NotificationCountProvider>
                            </div>
                            
                            )
                           })
                        }
                   
                        <Dropdown
                            overlay={this.userDivContainerMenu()}
                            trigger={["click"]}
                            placement="bottomRight"
                            >
                                <div className='profile-name'>
                                    <div  className={'circle'}>
                                          <Icon style={{ fontSize: '20px',position:"relative",top:'-17px', left:'-3px' }} type="user" />
                                    </div>
                                    <span className="tw-icon tw-icon-chevron-up tw-chevron chevron-color bottom m-l-1" aria-hidden="true" role="none" data-testid="chevron-up-icon"><svg width="16" height="16" fill="currentColor" focusable="false" viewBox="0 0 24 24"><path d="M12.771 7.115a.829.829 0 0 0-1.2 0L3 15.686l1.2 1.2 7.971-7.971 7.972 7.971 1.2-1.2-8.572-8.571Z"></path></svg></span>
                                </div>
                               
                            {/* <Button
                                icon="user"
                                type="primary"
                            >
                                {this.state.iconName == "left"
                                ? this.authenticationService.getUsername()
                                : ""}
                            </Button> */}
                            </Dropdown>
                    </Col>
                </Row>
            </Layout.Header>
        )
    }
}

export default AppHeader;