import * as React from 'react'
import { Spin } from 'antd'
import { LoadingComponentProps } from 'react-loadable';

export interface LoadingProps extends LoadingComponentProps
{
  
}
const Loading = (props: LoadingProps) => (
  <div hidden={props.isLoading} style={{ paddingTop: 100, textAlign: 'center' }}>
    <Spin size="large" />
  </div>
);
export default Loading;
