
import { Services } from "../static-services";
import { SignalrService } from "../../services/signalr-service";
import { Row } from "../../services/data-service";
import { AccessService } from "../../services/access-service";
import axios from 'axios';

export interface IRequestError { Code: string; Message?: string; Data?: any };
export type RequestWarning = IRequestError;


export interface ResponseWrapper<TResponse> {
    Error?: IRequestError;
    Response?: TResponse;
    Warnings?: RequestWarning[];
    RequestId: string;
}

export type StdRequestFile = { name: string, file: File }

const S4 = () => (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
const guid = () => (S4() + S4() + "-" + S4() + "-4" + S4().substr(0, 3) + "-" + S4() + "-" + S4() + S4() + S4()).toLowerCase();

export class StdRequest<TResponse>
{
    constructor(protected accessService: AccessService, public Type: string, public post = true, public files?: StdRequestFile[]) {
        if (!!files && !post) {
            throw "StdRequest: Sending files is available only on post requests";
        }
    }

    send() {
        return new Promise<ResponseWrapper<TResponse>>((resolve) => {
            const signalrService = this.accessService.signalrService;
            const sessionKey = this.accessService.sessionKey;
            const id = guid();
            const requestBody = JSON.stringify({ ...this, Id: id, SessionKey: sessionKey, accessService: undefined, files: undefined });
            console.log('request body', requestBody);
            const error = this.error.bind(this, resolve, id);
            if (this.post) {
                const formData = new FormData();
                formData.append('Id', id);
                formData.append('Type', this.Type);
                formData.append('Request', requestBody);
                if (!!this.files && this.files.length > 0) {
                    for (let i = 0; i < this.files.length; i++) {
                        const file = this.files[i].file;
                        const id = this.files[i].name;
                        if (file) {
                            formData.append(id, file);
                        }
                    }
                }
                console.log('request', formData);
                axios.post(signalrService.postRequestUrl, formData,
                    {
                        headers:
                        {
                            'Content-Type': 'multipart/form-data'
                        }
                    }).then(axiosResponse => {
                        console.log('axios response', axiosResponse);
                        const responseWrapper = axiosResponse.data;
                        if (responseWrapper == null) {
                            console.log("invalid response");
                            error({ Code: "Invalid response" });
                            return;
                        }

                        if (responseWrapper.RequestId !== id) {
                            console.log("responseWrapper.RequestId !== id", responseWrapper.RequestId, id);
                            return;
                        }
                            
                        console.log('response', responseWrapper, formData);
                        resolve(responseWrapper);
                    });
                return;
            }
            signalrService.whenOpen(() => {
                const connection = signalrService.connection;

                connection.on("StdResponse", (responseWrapper: ResponseWrapper<TResponse>) => {
                    if (responseWrapper.RequestId !== id)
                        return;

                    if (responseWrapper == null) {
                        error({ Code: "Invalid response" });
                        return;
                    }

                    resolve(responseWrapper);
                });
                connection.send("ProcessRequest", this.Type, id, requestBody)
                    .then(undefined, (r: any) => {
                        console.error(r);
                        this.send().then(responseWrapper => resolve(responseWrapper));
                    })
                    .catch((e: any) => {
                        console.error(e);
                        this.send().then(responseWrapper => resolve(responseWrapper));
                    })
            });
        }).then(responseWrapper => { console.log([this.Type + ' response', responseWrapper]); return responseWrapper; });
    }

    protected error(resolve: any, requestId: string, error: IRequestError) {
        resolve({ Error: { Type: "Invalid response" }, RequestId: requestId });
    }
}

export type FieldUpdate = { table: string, rowId: string, field: string, value: string };
export type RowsDeleted = { Table: string, Ids: string[] };
export type NewRow = { Table: string, RowId: string, Row: Row };