import {StdRequest} from "./signalr-utils";
import {UniqueColumns, Schema, Tables, FieldValue, Row} from "../../services/data-service";
import {AccessService} from "../../services/access-service";

///////////////////////////

export class LoginResponse {
    
    SessionKey!: string;
    SessionExpires!: FieldValue;
    UserId!: string;
    Username!: string;
    Schema!: Schema;
    Tables!: Tables;
    UniqueColumns!: UniqueColumns;
    RoleColumnAccessData!:  { [table: string]: { column: string, access: string } };
    Role!: string;
}

export class LoginRequest extends StdRequest<LoginResponse> {
    public constructor(accessService: AccessService, public username?: string, public password?: string) {
        super(accessService, "LoginRequest");
    }
}

/////////////////////////////

export type SetFieldResponse = any;

export class SetFieldRequest extends StdRequest<SetFieldResponse> {
    public constructor(accessService: AccessService, public table: string, public rowId: string, public field: string, public value: FieldValue) {
        super(accessService, "SetFieldRequest");
    }
}

