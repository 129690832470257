import * as signalR from "@aspnet/signalr";
import { FieldUpdate, NewRow, RowsDeleted } from "../helpers/singalr/signalr-utils";
import { waitFor } from "../helpers/utils";
import { observable, computed } from "mobx";

export class SignalrService
{
    serverUrl = window.any.location.origin + '/';
    hubUrl = this.serverUrl + 'main';
    postRequestUrl = this.serverUrl + 'postrequest';
    @observable
    protected _connectionOpen = false;
    protected _connection!: signalR.HubConnection;

    constructor()
    {
        this.connect(true);
    }

    protected connect(tryIndefinitely = false)
    {
        this._connectionOpen = true;
        return new Promise<void>(() => { });

        //try
        //{
        //    if(this._connection != undefined)
        //        this._connection.stop();
        //}
        //catch(e)
        //{
        //    console.error(e);
        //}

        //this._connectionOpen = false;
        //this._connection = new signalR.HubConnectionBuilder()
        //    .withUrl(this.hubUrl, signalR.HttpTransportType.LongPolling)
        //    .configureLogging(signalR.LogLevel.Trace)
        //    .build();
        //this._connection.serverTimeoutInMilliseconds = 60*60*1000;

        //const promise = this._connection.start();
        //promise.then(() =>
        //{
        //    this._connectionOpen = true;
        //    this._connection.onclose(() => this.connect(tryIndefinitely))
        //})
        //.catch(e => 
        //{
        //    console.log('err', e);
        //    setTimeout(() => {
        //        if (tryIndefinitely)
        //            this.connect(tryIndefinitely);
        //    }, 3000);
        //    console.error(e);
            
        //});
        //return promise;
    }

    @computed
    get connectionOpen()
    {
        return this._connectionOpen;
    }

    whenOpen(callback: () => void)
    {
        return waitFor(() => this.connectionOpen, callback)
    }
    
    get connection(): signalR.HubConnection
    {
        return this._connection;
    }

    onFieldUpdate(callback: (fieldUpdate: FieldUpdate) => void)
    { 
        return;

        this.whenOpen(() =>
        {
            this.connection.on("FieldUpdate", callback);
        })
    }

    onNewRow(callback: (fieldUpdate: NewRow) => void)
    { 
        return;


        this.whenOpen(() =>
        {
            this.connection.on("NewRow", callback);
        })
    }

    onRowsDeleted(callback: (fieldUpdate: RowsDeleted) => void)
    { 
        return;


        this.whenOpen(() =>
        {
            this.connection.on("RowsDeleted", callback);
        })
    }
}