import { DefaultLoadable } from "./components/DefaultLoadable";
import { AuthenticationService } from "./services/authenticationService";
import groupIcon from "./images/group-icon.png";
import keyIcon from "./images/Key.png";
import { ReactComponent as integrations } from "./images/update-setting.svg";
import { Roles } from "./lib/roles";

export class Routes {
  authenticationService: AuthenticationService;
  constructor() {
    this.authenticationService = new AuthenticationService();
  }

  getRoutes():RoutesMenuItem[] {
    return [
      {
        path: "/campaigns-dev",
        title: "Campaigns",
        icon: "rise",
        iconType: "antd",
        showInMenu: this.authenticationService.isInOneOfRole([
          Roles.Admin,
          Roles.CampaignManager,
          Roles.Specialist,
          Roles.SpecialistContact,
          Roles.Listinger,
          Roles.ListingerDev,
          Roles.Analyst,
          Roles.CustomerService,
        ]),
        available: this.authenticationService.isInOneOfRole([
          Roles.Admin,
          Roles.CampaignManager,
          Roles.Specialist,
          Roles.SpecialistContact,
          Roles.Listinger,
          Roles.ListingerDev,
          Roles.Analyst,
          Roles.CustomerService,
        ]),
        component: DefaultLoadable(
          () => import("./scenes/Campaigns/CampaignsView")
        ),
      },
      {
        path: "/websites",
        requiredTable: "Websites",
        title: "Websites",
        icon: "global",
        iconType: "antd",
        showInMenu: this.authenticationService.isInOneOfRole([
          Roles.Admin,
          Roles.CampaignManager,
          Roles.Developer,
          Roles.DeveloperPro,
          Roles.Specialist,
          Roles.SpecialistContact,
          Roles.Analyst,
          Roles.ListingerDev,
          Roles.CustomerService,
        ]),
        available: this.authenticationService.isInOneOfRole([
          Roles.Admin,
          Roles.CampaignManager,
          Roles.Developer,
          Roles.DeveloperPro,
          Roles.Specialist,
          Roles.SpecialistContact,
          Roles.Analyst,
          Roles.ListingerDev,
          Roles.CustomerService,
        ]),
        component: DefaultLoadable(
          () => import("./scenes/WebsitesViews/WebsitesViews")
        ),
      },
      {
        path: "/contact-persons-dev",
        title: "Contacts",
        icon: "phone",
        iconType: "antd",
        showInMenu: this.authenticationService.isInOneOfRole([
          Roles.Admin,
          Roles.CampaignManager,
          Roles.SpecialistContact,
          Roles.CustomerService,
        ]),
        component: DefaultLoadable(
          () => import("./scenes/ContactPersons/ContactPersonsView")
        ),
      },
      {
        title: "Tools",
        path: "/tools-menu",
        icon: "tool",
        iconType: "antd",
        showInMenu: true,
        subMenuItems: [
          {
            path:"",
            subMenuPath: "/simulator",
            requiredTable: "Simulator",
            title: "Simulator",
            icon: "search",
            iconType: "antd",
            showInMenu: true,
            available: true,
            description:"Simulator description"
          },
          {
            path:"",
            subMenuPath: "/access-dev",
            title: "Access",
            name: "websiteslookup",
            icon: "lock",
            iconType: "antd",
            description:"Access description",
            showInMenu: this.authenticationService.isInOneOfRole([
              Roles.Admin,
              Roles.CampaignManager,
              Roles.Specialist,
              Roles.SpecialistContact,
              Roles.Texter,
              Roles.Developer,
              Roles.DeveloperPro,
              Roles.Analyst,
              Roles.Listinger,
              Roles.ListingerDev,
              Roles.CustomerService,
            ]),
            available: this.authenticationService.isInOneOfRole([
              Roles.Admin,
              Roles.CampaignManager,
              Roles.Specialist,
              Roles.SpecialistContact,
              Roles.Texter,
              Roles.Developer,
              Roles.DeveloperPro,
              Roles.Analyst,
              Roles.Listinger,
              Roles.ListingerDev,
              Roles.CustomerService,
            ]),
            component: DefaultLoadable(
              () => import("./scenes/Access/AccessView")
            ),
          },
          {
            path:"",
            subMenuPath: "/text-message-dev",
            title: "SMS",
            name: "user",
            icon: "message",
            iconType: "antd",
            description:"SMS description",
            showInMenu: this.authenticationService.isInOneOfRole([
              Roles.Admin,
              Roles.CampaignManager,
              Roles.Specialist,
              Roles.SpecialistContact,
              Roles.Texter,
              Roles.Analyst,
              Roles.CustomerService,
            ]),
          },
        ],
        component: DefaultLoadable(
          () => import("./scenes/SubmenusView")
        ),
      },
      {
        title: "Reports",
        path: "/reports-menu",
        icon: "file-search",
        iconType: "antd",
        component: DefaultLoadable(
          () => import("./scenes/SubmenusView")
        ),
        showInMenu: this.authenticationService.isInOneOfRole([
          Roles.Admin,
          Roles.CampaignManager,
          Roles.Specialist,
          Roles.SpecialistContact,
          Roles.CustomerService,
        ]),
        subMenuItems: [
          {
            path:"",
            subMenuPath: "/report-email-bulk",
            title: "Reports",
            name: "Reports",
            icon: "file-done",
            iconType: "antd",
            description:"Reports description",
            showInMenu: this.authenticationService.isInOneOfRole([
              Roles.Admin,
              Roles.CampaignManager,
              Roles.Specialist,
              Roles.SpecialistContact,
            ]),
            available: this.authenticationService.isInOneOfRole([
              Roles.Admin,
              Roles.CampaignManager,
              Roles.Specialist,
              Roles.SpecialistContact,
            ]),
             component: DefaultLoadable(
          () => import("./scenes/SubmenusView")
        ),
          },
          {
            path:"",
            subMenuPath: "/profit-ratio-bulk",
            title: "Cost Per Lead",
            name: "Cost Per Lead",
            icon: "calculator",
            iconType: "antd",
            description:"Cost per lead description",
            showInMenu: this.authenticationService.isInOneOfRole([
              Roles.Admin,
              Roles.CampaignManager,
              Roles.Specialist,
              Roles.SpecialistContact,
              Roles.CustomerService,
            ]),
            available: this.authenticationService.isInOneOfRole([
              Roles.Admin,
              Roles.CampaignManager,
              Roles.Specialist,
              Roles.SpecialistContact,
              Roles.CustomerService,
            ]),
          },
        ],
      },
      {
        title: "Notifications",
        path: "/login",
        icon: "alert",
        iconType: "antd",
        showInMenu: this.authenticationService.isInOneOfRole([
          Roles.Admin,
          Roles.CampaignManager,
          Roles.Analyst,
          Roles.Developer,
          Roles.DeveloperPro,
          Roles.Specialist,
          Roles.SpecialistContact,
        ]),
        subMenuItems: [
          {
            path:"",
            subMenuPath: "/listingsnotif-dev",
            title: "GMB Notifications",
            shortTitle: "GMB",
            name: "listingsnotif",
            icon: "notification",
            iconType: "antd",
            showInMenu: this.authenticationService.isInOneOfRole([
              Roles.Admin,
              Roles.CampaignManager,
              Roles.Analyst,
              Roles.Specialist,
              Roles.SpecialistContact,
            ]),
          },

          {
            path:"",
            subMenuPath: "/wpLabs",
            title: "Web Monitoring",
            shortTitle: "Web",
            name: "wpLabs",
            icon: "experiment",
            iconType: "antd",
            showInMenu: this.authenticationService.isInOneOfRole([
              Roles.Admin,
              Roles.Developer,
              Roles.DeveloperPro,
              Roles.CampaignManager,
            ]),
          },
        ],
      },
      {
        path: "/listingsnotif-dev",
        title: "GMB Notifications",
        name: "listingsnotif",
        icon: "alert",
        iconType: "antd",
        showInMenu: this.authenticationService.isInOneOfRole([
          Roles.Admin,
          Roles.CampaignManager,
          Roles.Analyst,
        ]),
        component: DefaultLoadable(
          () => import("./scenes/ListingsInfoStatus/ListingsInfoStatus")
        ),
        routeOnly: true,
      },
      {
        path: "/wpLabs",
        title: "Web Monitoring",
        name: "wpLabs",
        icon: "alert",
        iconType: "antd",
        showInMenu: this.authenticationService.isInOneOfRole([
          Roles.Admin,
          Roles.Developer,
          Roles.DeveloperPro,
          Roles.CampaignManager,
        ]),
        component: DefaultLoadable(() => import("./scenes/WpLabs/WpLabs")),
        routeOnly: true,
      },
      {
        title: "Advanced",
        path: "/advanced-menu",
        icon: "setting",
        iconType: "antd",
        component: DefaultLoadable(
          () => import("./scenes/SubmenusView")),
        showInMenu: this.authenticationService.isInOneOfRole([
          Roles.Admin,
          Roles.CampaignManager,
          Roles.Specialist,
          Roles.SpecialistContact,
          Roles.Listinger,
          Roles.ListingerDev,
          Roles.Analyst,
          Roles.Texter,
          Roles.CustomerService,
        ]),
        subMenuItems: [
          {
            path:"",
            subMenuPath: "/listings-dev",
            title: "Listings",
            icon: "unordered-list",
            iconType: "antd",
            showInMenu: this.authenticationService.isInOneOfRole([
              Roles.Admin,
              Roles.CampaignManager,
              Roles.Specialist,
              Roles.SpecialistContact,
              Roles.Listinger,
              Roles.ListingerDev,
              Roles.Analyst,
            ]),
            available: this.authenticationService.isInOneOfRole([
              Roles.Admin,
              Roles.CampaignManager,
              Roles.Specialist,
              Roles.SpecialistContact,
              Roles.Listinger,
              Roles.ListingerDev,
              Roles.Analyst,
            ]),
          },
          {
            path:"",
            subMenuPath: "/google-accounts-dev",
            requiredTable: "Google Account",
            title: "Integrations",
            icon: integrations,
            iconType: "png",
            showInMenu: this.authenticationService.isInOneOfRole([
              Roles.Admin,
              Roles.Listinger,
              Roles.ListingerDev,
              Roles.CampaignManager,
              Roles.Specialist,
              Roles.SpecialistContact,
            ]),
            available: this.authenticationService.isInOneOfRole([
              Roles.Admin,
              Roles.Listinger,
              Roles.ListingerDev,
              Roles.CampaignManager,
              Roles.Specialist,
              Roles.SpecialistContact,
            ]),
          },
          {
            path:"",
            subMenuPath: "/settings",
            title: "Settings",
            name: "settings",
            icon: "setting",
            iconType: "antd",
            showInMenu: this.authenticationService.isInOneOfRole([Roles.Admin]),
          },
        ],
      },
      {
        path: "/access-dev",
        title: "Access",
        name: "websiteslookup",
        icon: "lock",
        iconType: "antd",
        showInMenu: this.authenticationService.isInOneOfRole([
          Roles.Admin,
          Roles.CampaignManager,
          Roles.Specialist,
          Roles.SpecialistContact,
          Roles.Texter,
          Roles.Developer,
          Roles.DeveloperPro,
          Roles.Analyst,
          Roles.Listinger,
          Roles.ListingerDev,
          Roles.CustomerService,
        ]),
        component: DefaultLoadable(() => import("./scenes/Access/AccessView")),
        routeOnly: true,
      },
      {
        path: "/teamaccess-dev",
        title: "Team Access",
        name: "internalaccess",
        icon: groupIcon,
        iconType: "png",
        showInMenu: this.authenticationService.isInOneOfRole([
          Roles.Admin,
          Roles.CampaignManager,
          Roles.Specialist,
          Roles.SpecialistContact,
          Roles.Texter,
          Roles.Developer,
          Roles.DeveloperPro,
          Roles.Analyst,
          Roles.Listinger,
          Roles.ListingerDev,
        ]),
        component: DefaultLoadable(
          () => import("./scenes/InternalAccess/InternalAccess")
        ),
        routeOnly: true,
      },
      {
        path: "/personalaccess-dev",
        title: "Personal Access",
        name: "personalaccess",
        icon: "lock",
        iconType: "antd",
        showInMenu: this.authenticationService.isInOneOfRole([
          Roles.Admin,
          Roles.CampaignManager,
          Roles.Specialist,
          Roles.SpecialistContact,
          Roles.Developer,
          Roles.DeveloperPro,
          Roles.Texter,
          Roles.Analyst,
          Roles.Listinger,
          Roles.ListingerDev,
        ]),
        component: DefaultLoadable(
          () => import("./scenes/PersonalAccess/PersonalAccess")
        ),
        routeOnly: true,
      },
      {
        path: "/email-settings",
        title: "Email",
        icon: "mail",
        iconType: "antd",
        showInMenu: this.authenticationService.isInOneOfRole([
          Roles.Admin,
          Roles.CampaignManager,
          Roles.Specialist,
          Roles.SpecialistContact,
        ]),
        available: true,
        routeOnly: true,
        component: DefaultLoadable(
          () => import("./scenes/EmailSettings/EmailSettings")
        ),
      },
      {
        path: "/listings-dev",
        title: "Listings",
        icon: "unordered-list",
        iconType: "antd",
        showInMenu: this.authenticationService.isInOneOfRole([
          Roles.Admin,
          Roles.CampaignManager,
          Roles.Specialist,
          Roles.SpecialistContact,
          Roles.Listinger,
          Roles.ListingerDev,
          Roles.Analyst,
        ]),
        available: this.authenticationService.isInOneOfRole([
          Roles.Admin,
          Roles.CampaignManager,
          Roles.Specialist,
          Roles.SpecialistContact,
          Roles.Listinger,
          Roles.ListingerDev,
          Roles.Analyst,
        ]),
        routeOnly: true,
        component: DefaultLoadable(
          () => import("./scenes/Listings/ListingsView")
        ),
      },
      {
        path: "/google-accounts-dev",
        requiredTable: "Google Account",
        title: "Google Accounts",
        icon: keyIcon,
        iconType: "png",
        showInMenu: this.authenticationService.isInOneOfRole([
          Roles.Admin,
          Roles.Listinger,
          Roles.ListingerDev,
          Roles.CampaignManager,
          Roles.Specialist,
          Roles.SpecialistContact,
        ]),
        available: this.authenticationService.isInOneOfRole([
          Roles.Admin,
          Roles.Listinger,
          Roles.ListingerDev,
          Roles.CampaignManager,
          Roles.Specialist,
          Roles.SpecialistContact,
        ]),
        routeOnly: true,
        component: DefaultLoadable(
          () => import("./scenes/Integrations/GoogleAccountsView")
        ),
      },
      {
        path: "/users-dev",
        title: "Users",
        name: "user",
        icon: "team",
        iconType: "antd",
        showInMenu: this.authenticationService.isInOneOfRole([Roles.Admin]),
        routeOnly: true,
        component: DefaultLoadable(() => import("./scenes/Users/UsersView")),
      },
      {
        path: "/text-message-dev",
        title: "Text Message",
        name: "user",
        icon: "message",
        iconType: "antd",
        showInMenu: this.authenticationService.isInOneOfRole([
          Roles.Admin,
          Roles.CampaignManager,
          Roles.Specialist,
          Roles.SpecialistContact,
          Roles.Texter,
          Roles.Analyst,
          Roles.CustomerService,
        ]),
        routeOnly: true,
        component: DefaultLoadable(
          () => import("./scenes/SmsGateways/SmsGatewayView")
        ),
      },
      {
        path: "/form-messages-dev",
        title: "Form Messages",
        name: "form-messages",
        icon: "message",
        iconType: "antd",
        showInMenu: this.authenticationService.isInOneOfRole([
          Roles.Admin,
          Roles.CampaignManager,
        ]),
        routeOnly: true,
        component: DefaultLoadable(
          () => import("./scenes/FormMessages/FormMessages")
        ),
      },
      {
        path: "/form-settings-dev",
        title: "Form Settings",
        name: "form-settings",
        icon: "message",
        iconType: "antd",
        showInMenu: this.authenticationService.isInOneOfRole([
          Roles.Admin,
          Roles.CampaignManager,
        ]),
        routeOnly: true,
        component: DefaultLoadable(
          () => import("./scenes/FormMessages/FormSettings")
        ),
      },
      {
        path: "/checker-settings",
        title: "Checker Settings",
        name: "form-messages",
        icon: "setting",
        iconType: "antd",
        showInMenu: this.authenticationService.isInOneOfRole([Roles.Admin]),
        routeOnly: true,
        component: DefaultLoadable(
          () => import("./scenes/SeoGmbCheckerSettings/SeoGmbCheckerSettings")
        ),
      },
      {
        path: "/settings",
        title: "Settings",
        name: "settings",
        icon: "setting",
        iconType: "antd",
        showInMenu: this.authenticationService.isInOneOfRole([Roles.Admin]),
        routeOnly: true,
        component: DefaultLoadable(
          () => import("./scenes/Settings/SettingsView")
        ),
      },
      {
        path: "/simulator",
        title: "Search Simulator",
        name: "simulator",
        icon: "search",
        iconType: "antd",
        showInMenu: true,
        routeOnly: true,
        component: DefaultLoadable(
          () => import("./scenes/SearchSimulator/SearchSimulator")
        ),
      },
      {
        path: "/review-begger-bulk",
        title: "Review begger bulk view",
        name: "simulator",
        icon: "search",
        iconType: "antd",
        showInMenu: true,
        routeOnly: true,
        component: DefaultLoadable(
          () => import("./scenes/ReviewBegger/ReviewBeggerBulkView")
        ),
      },
      {
        path: "/report-email-bulk",
        title: "Report email bulk view",
        routeOnly: true,
        component: DefaultLoadable(
          () => import("./scenes/ReportCreator/ReportCreatorEmailBulkView")
        ),
      },
      {
        path: "/profit-ratio-bulk",
        title: "Profit ratio bulk view",
        routeOnly: true,
        component: DefaultLoadable(
          () => import("./scenes/ProfitRatio/ProfitRatioBulkView")
        ),
      },
      {
        path: "/services-dev",
        title: "Services",
        name: "services",
        icon: "message",
        iconType: "antd",
        showInMenu: this.authenticationService.isInOneOfRole([Roles.Admin]),
        component: DefaultLoadable(
          () => import("./scenes/Services/ServicesView")
        ),
        routeOnly: true,
      },
      {
        path: "/countries-dev",
        title: "Countries",
        name: "countries",
        icon: "message",
        iconType: "antd",
        showInMenu: this.authenticationService.isInOneOfRole([Roles.Admin]),
        component: DefaultLoadable(
          () => import("./scenes/Country/CountryView")
        ),
        routeOnly: true,
      },
      {
        path: "/senderforscanning-dev",
        title: "Sender For Scanning",
        name: "sender for scanning",
        icon: "message",
        iconType: "antd",
        showInMenu: this.authenticationService.isInOneOfRole([Roles.Admin]),
        component: DefaultLoadable(
          () => import("./scenes/SenderForScanning/SenderForScanningView")
        ),
        routeOnly: true,
      },
      {
        path: "/industry-dev",
        title: "Industries",
        name: "industries",
        icon: "message",
        iconType: "antd",
        showInMenu: this.authenticationService.isInOneOfRole([Roles.Admin]),
        component: DefaultLoadable(() => import("./scenes/Industry/Industry")),
        routeOnly: true,
      },
      {
        path: "/paidadstypes-dev",
        title: "Paid Ads Types",
        name: "paidadstypes",
        icon: "message",
        iconType: "antd",
        showInMenu: this.authenticationService.isInOneOfRole([Roles.Admin]),
        component: DefaultLoadable(
          () => import("./scenes/PaidAdsTypes/PaidAdsTypesView")
        ),
        routeOnly: true,
      },
      {
        path: "/logout",
        title: "Logout",
        name: "logout",
        icon: "logout",
        iconType: "antd",
        showInMenu: false,
        component: DefaultLoadable(() => import("./scenes/Logout")),
      },
    ];
  }
}

export interface RoutesMenuItem {
  path: string;
  subMenuPath?: string;
  title?: string;
  icon?: string|any;
  iconType?: string;
  showInMenu?: boolean;
  available?: boolean;
  requiredTable?: string;
  component?: any;
  subMenuItems?: RoutesMenuItem[];
  name?: string;
  shortTitle?: string;
  routeOnly?: boolean;
  history?:any;
  description?:string;
}

export function isActiveMenu(route: RoutesMenuItem)
  {
    // this.newMenuRoutes.
    // console.log(route);
    return findMenuItemByPath(route,window.location.pathname);
  }

export function findMenuItemByPath(route: RoutesMenuItem, path: string): RoutesMenuItem | undefined {
    if (route.path === path) {
      return route;
    }
  
    if (route.subMenuItems) {
      return route.subMenuItems.find(x=>x.subMenuPath == path);
    }
  
    return undefined;
  }
// export interface RoutesSubMenuItem {
//   subMenuPath: string;
//   title: string;
//   icon: string;
//   iconType: string;
//   showInMenu: boolean;
//   available?: boolean;
//   name?: string;
//   shortTitle?: string;
//   requiredTable?: string;
//   component?: () => Promise<any>;
// }