import * as React from "react";
import { Route, Redirect, Link } from "react-router-dom";
import { AuthenticationService } from "../../services/authenticationService";
import { Menu, Dropdown, Button, Icon, Layout, Badge, Row, Col } from "antd";
import { Routes, RoutesMenuItem, isActiveMenu } from "../../routes";
import {
  ListingInfoClient,
  SeoNotifsClient,
  SmsClient,
  WebsiteMonitoringClient,
} from "../../api/ToolApiClient";
import { wpLabsHistNotifsSvc } from "../../services/wpLabsNotificationsService";
import SubMenu from "antd/lib/menu/SubMenu";
import { AppState } from "../../App";
import { Roles } from "../../lib/roles";
import { useQuery } from "react-query";
import { queryKeys } from "../../react-query/queryKeys";
import AppHeaderSideBar from "../AppHeaderSideBar";
import "./side-bar-menu.css";
import { Services } from "../../helpers/static-services";
const { Sider } = Layout;

export interface ISideMenuProps {
  counters: AppState;
}

export interface ISideMenuState {
  wpLabsHistNotifsCnt: number;
  iconName: string;
  isTextMessage: boolean;
  arrowIconLeft: number;
  menuWidth: number;
}

export class SideMenu extends React.Component<ISideMenuProps, ISideMenuState> {
  routes = new Routes().getRoutes();
  menuRoutes = this.routes.filter((route) => route.showInMenu);
  visibleRoutes = [];//this.menuRoutes;
  //visibleRoutes = this.menuRoutes;
  
  newMenuRoutes = this.routes.filter((route)=>{
    return route.showInMenu&&!route.routeOnly && route.title != "Notifications";
  });


  //newMenuRoutes = [];
  mainMenuService = Services.mainMenuService;
  

  authenticationService = new AuthenticationService();
  private websiteMonitoringClient: WebsiteMonitoringClient =
    new WebsiteMonitoringClient();
  smsClientApi: SmsClient;
  sidemenuRef: any = React.createRef<SubMenu>();

  
  constructor(props) {
    super(props);
    const menuWidth = localStorage.getItem("menuWidth");
    this.smsClientApi = new SmsClient(window.location.origin);
    
    // this.state = {
    //   wpLabsHistNotifsCnt: 0,
    //   iconName: menuWidth && menuWidth == "82" ? "right" : "left",
    //   isTextMessage: false,
    //   arrowIconLeft: 118,
    //   menuWidth: menuWidth ? Number(menuWidth) : 150,
    // };
    
    this.state = {
      wpLabsHistNotifsCnt: 0,
      iconName: menuWidth && menuWidth == "82" ? "right" : "left",
      isTextMessage: false,
      arrowIconLeft: 118,
      menuWidth: 200,
    };

  }

  listingNotifClient = new ListingInfoClient();
  seoNotifClient = new SeoNotifsClient();
  wpLabsNotifClient = new ListingInfoClient();
  wpLabsHistNotifClient = new SeoNotifsClient();

  async componentDidMount() {
    wpLabsHistNotifsSvc.wpLabsHistNotifObservable().subscribe((res) => {
      if (res) {
        const x = res;
        if (x.length > 0) {
          this.setState({ ...this.state, wpLabsHistNotifsCnt: x.length });
        } else {
          this.setState({ ...this.state, wpLabsHistNotifsCnt: 0 });
        }
      }
    });

    const value = await this.smsClientApi.showTextMessaage();
    this.setState({ isTextMessage: value });
  }

  userDropdownMenu() {
    return (
      <Menu>
        <Menu.Item key="2">
          <Link to="/logout">
            <Icon type="logout" />
            <span style={{ marginLeft: "10px" }}>Logout</span>
          </Link>
        </Menu.Item>
      </Menu>
    );
  }

  closeAdvancedSubMenu = () => {
    this.sidemenuRef.triggerOpenChange(false);
  };

  render() {
    return (
      <div>
        <AppHeaderSideBar></AppHeaderSideBar>
      <Sider width={this.state.menuWidth} collapsed={!true}>
        <Route
          render={({ history }) => (
            <>
            <div className="menu-section">
             {this.newMenuRoutes.map((route, index) => {
              return (<div >
                { <Link to={route.path} className={`${(isActiveMenu(route))?"active":""} generic-menu-item`}>
                            <div className="icon-container">
                              {route.iconType != "antd" ? (
                                <img src={route.icon} className="sidebar-image" />
                              ) : (
                                <Icon style={{ fontSize: '20px' }} type={route.icon} />
                              )}
                             </div>
                            {route.name !== "listingsnotif" &&
                              this.state.iconName == "left" && (
                                <span className="d-inline">{route.title}</span>
                              )}

                          </Link> }
              </div>)

            })}
            </div>
            
              <Menu
                style={{
                  marginTop: 5,
                  overflowY: "auto",
                  height: "90vh",
                  position: "relative",
                }}
                theme="light"
                mode="inline"
                selectedKeys={[window.location.pathname]}
              >

               


                {this.visibleRoutes.map((route, index) => {
                  if (!route.routeOnly && route.title != "Notifications") {
                    if (route.subMenuItems) {
                      return (
                        <SubMenu
                          ref={(elm) => (this.sidemenuRef = elm)}
                          key={"subMenu" + route.title}
                          className={
                            this.state.iconName == "left"
                              ? "maximized space-between-menu-tabs sidebar-menuItem-padding"
                              : "minimized space-between-menu-tabs sidebar-menuItem-padding"
                          }
                          title={
                            <span>
                              <Icon type={route.icon} />
                              {route.name !== "listingsnotif" &&
                                this.state.iconName == "left" && (
                                  <span>{route.title}</span>
                                )}
                            </span>
                          }
                        >
                          {route.subMenuItems
                            .filter((item) => item.showInMenu)
                            .map((subMenuItem) =>
                              (this.state.isTextMessage &&
                                subMenuItem.title === "Text Message") ||
                              !subMenuItem.showInMenu ? null : (
                                <Menu.Item
                                  className={`${
                                    window.location.pathname ==
                                    subMenuItem.subMenuPath
                                      ? "ant-menu-item-selected"
                                      : ""
                                  } cursor-pointer padding-submenu`}
                                  style={{ marginTop: 0 }}
                                  key={subMenuItem.subMenuPath}
                                >
                                  <Link
                                    to={subMenuItem.subMenuPath}
                                    className="d-inline"
                                  >
                                    {subMenuItem.iconType != "antd" ? (
                                      <Icon
                                        component={subMenuItem.icon}
                                        className="antd-component-icon"
                                      />
                                    ) : (
                                      <Icon type={subMenuItem.icon} />
                                    )}
                                    {subMenuItem.name !== "listingsnotif" &&
                                      this.state.iconName == "left" && (
                                        <span className="d-inline">
                                          {subMenuItem.title}
                                        </span>
                                      )}
                                  </Link>
                                </Menu.Item>
                              )
                            )}
                        </SubMenu>
                      );
                    } else {
                      return (
                        <Menu.Item
                          style={{ marginTop: 0 }}
                          className={
                            "space-between-menu-tabs sidebar-menuItem-padding"
                          }
                          key={route.path}
                          onClick={() => {
                            this.closeAdvancedSubMenu();
                          }}
                        >
                          <Link to={route.path} className="d-inline">
                            {route.iconType != "antd" ? (
                              <img src={route.icon} className="sidebar-image" />
                            ) : (
                              <Icon type={route.icon} />
                            )}
                            {route.name !== "listingsnotif" &&
                              this.state.iconName == "left" && (
                                <span className="d-inline">{route.title}</span>
                              )}
                          </Link>
                        </Menu.Item>
                      );
                    }
                  }
                })}
                {this.state.iconName == "left" && (
                  <div
                    style={{
                      position: "fixed",
                      bottom: "5px",
                      left: "3px",
                      width: "150px",
                      marginBottom: 40,
                    }}
                  >
                    <Row className="notification-menu-item">
                      <Col className="d-flex" key="col-1">
                        {this.visibleRoutes.map((route) => {
                          if (route.title === "Notifications") {
                            return route.subMenuItems.map((subMenuItem) => {
                              return subMenuItem.showInMenu ? (
                                <NotificationCountProvider
                                  key={`${subMenuItem.subMenuPath}-notification-count-provider`}
                                >
                                  {(notificationCount) => (
                                    <Menu.Item
                                      key={subMenuItem.subMenuPath}
                                      className={`${
                                        window.location.pathname ==
                                        subMenuItem.subMenuPath
                                          ? "ant-menu-item-selected"
                                          : ""
                                      } cursor-pointer`}
                                      onClick={() => {
                                        this.closeAdvancedSubMenu();
                                      }}
                                      onItemHover={() => {}}
                                    >
                                      <Badge
                                        offset={[7, -7]}
                                        count={
                                          subMenuItem.name === "wpLabs"
                                            ? notificationCount.wpLabsNotifsCnt
                                            : subMenuItem.name ===
                                              "listingsnotif"
                                            ? notificationCount.notifsCnt
                                            : 0
                                        }
                                        key={`${subMenuItem.subMenuPath}-badge`}
                                      >
                                        <Link
                                          to={subMenuItem.subMenuPath}
                                          className={`${
                                            window.location.pathname ==
                                            subMenuItem.subMenuPath
                                              ? "ant-menu-item-selected"
                                              : ""
                                          } cursor-pointer`}
                                          key={`${subMenuItem.subMenuPath}-link`}
                                        >
                                          {subMenuItem.iconType != "antd" ? (
                                            <img
                                              src={subMenuItem.icon}
                                              className="bottom-menu-item-img"
                                            />
                                          ) : (
                                            <Icon
                                              type={subMenuItem.icon}
                                              key={`${subMenuItem.subMenuPath}-icon`}
                                            />
                                          )}
                                          <span>
                                            {this.state.iconName == "left"
                                              ? subMenuItem.shortTitle
                                              : ""}
                                          </span>
                                        </Link>
                                      </Badge>
                                    </Menu.Item>
                                  )}
                                </NotificationCountProvider>
                              ) : null;
                            });
                          }
                        })}
                      </Col>
                    </Row>
                  </div>
                )}
                <div
                  style={{
                    position: "fixed",
                    bottom: "5px",
                    left: "3px",
                    width: this.state.iconName == "left" ? "150px" : "auto",
                  }}
                >
                  <Row>
                    <Dropdown
                      overlay={this.userDropdownMenu()}
                      trigger={["click"]}
                      placement="topCenter"
                    >
                      <Button
                        icon="user"
                        type="primary"
                        style={{
                          width: this.state.iconName == "left" ? "97%" : "auto",
                        }}
                      >
                        {this.state.iconName == "left"
                          ? this.authenticationService.getUsername()
                          : ""}
                      </Button>
                    </Dropdown>
                  </Row>
                </div>
              </Menu>
            </>
          )}
        />
      </Sider>
      </div>
    );
  }
}

export function NotificationCountProvider(props: {
  children: (params: {
    seoNotifsCnt: number;
    notifsCnt: number;
    wpLabsNotifsCnt: number;
  }) => any;
}) {
  const hasPermission = !new AuthenticationService().isInOneOfRole([
    Roles.CustomerService,
  ]);

  const [wpLabsHistNotifsCnt, setwpLabsHistNotifsCnt] = React.useState(0);

  const { data } = useQuery(
    queryKeys.notificationCount,
    () => new WebsiteMonitoringClient().getAppNotifications(),
    {
      enabled: hasPermission,
      onSuccess: (queryResult) => {
        setwpLabsHistNotifsCnt(queryResult.webNotifications || 0);
      },
    }
  );

  // TODO is this dead code?
  React.useEffect(() => {
    wpLabsHistNotifsSvc.wpLabsHistNotifObservable().subscribe((res) => {
      if (res) {
        const x = res;
        if (x.length > 0) {
          setwpLabsHistNotifsCnt(x.length);
        } else {
          setwpLabsHistNotifsCnt(0);
        }
      }
    });
  }, []);

  const result = hasPermission
    ? {
        notifsCnt: data ? data.gmbNotifications || 0 : 0,
        seoNotifsCnt: data ? data.seoNotifications || 0 : 0,
        wpLabsNotifsCnt: wpLabsHistNotifsCnt,
      }
    : {
        notifsCnt: 0,
        seoNotifsCnt: 0,
        wpLabsNotifsCnt: 0,
      };

  return <>{props.children(result)}</>;
}

export const ProtectedRoute = ({ component: Component, ...rest }) => {
  const authenticationService = new AuthenticationService();
  return (
    <Route
      {...rest}
      render={(props) => {
        if (authenticationService.isAuthenticated()) {
          return <Component exact {...rest} {...props} />;
        } else {
          return <Redirect to={"/login"} />;
        }
      }}
    />
  );
};

export default ProtectedRoute;
