//@ts-ignore
declare global
{
    interface String
    {
        firstToUpper(): string;
        replaceAll(search: string, replacement: string): string;
    }
}


String.prototype.firstToUpper = function()
{
    return this.charAt(0).toUpperCase() + this.slice(1);
}

String.prototype.replaceAll = function(search: string, replacement: string) 
{
    return this.replace(new RegExp(search, 'g'), replacement);
};

export {}