export class UserRoleType {

    static Admin="Admin";
    static CampaignManager="CampaignManager";
    static Specialist="Specialist";
    static SpecialistContact="SpecialistContact";
    static Developer="Developer";
    static DeveloperPro="DeveloperPro";
    static Unassigned="Unassigned";



}