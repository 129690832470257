import React from "react";
import "./App.css";
import { Layout } from "antd";

import { observer } from "mobx-react";
import { Services } from "./helpers/static-services";
import { Route, Redirect, Switch } from "react-router";
import { DefaultLoadable } from "./components/DefaultLoadable";
import { Routes } from "./routes";
import { AuthenticationService } from "./services/authenticationService";
import {
  ProtectedRoute,
  SideMenu,
} from "./components/ProtectedRoute/ProtectedRoute";
import { QueryClientProvider } from "./react-query/QueryClientProvider";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "ag-grid-enterprise";
import AppHeader from "./components/AppHeader";

export type AppProps = {};

export interface AppState {
  sideMenuWidth: number;
}

@observer
class App extends React.Component<AppProps, AppState> {
  mainMenuService = Services.mainMenuService;
  authenticationService = new AuthenticationService();

  constructor(props: AppProps) {
    super(props);

    const menuWidth = localStorage.getItem("menuWidth");

    this.state = {
      sideMenuWidth: menuWidth ? parseInt(menuWidth) : 150,
    };
  }

  loginPage() {
    return DefaultLoadable(() => import("./scenes/Login"));
  }

  render() {
    let routes = new Routes().getRoutes();

    const firstRoute = this.authenticationService.isAuthenticated()
      ? "/campaigns-dev"
      : "/login";

    return (
      <QueryClientProvider>
        <Switch>
          <Route
            exact
            key={"default"}
            path={"/"}
            render={() => <Redirect to={firstRoute}></Redirect>}
          />
          <Route
            exact
            key={"login"}
            path={"/login"}
            component={DefaultLoadable(() => import("./scenes/Login"))}
          />
          <Route
            exact
            key={"campaignReport"}
            path={"/campaignreports/:campaignId/:reportId"}
            component={DefaultLoadable(
              () => import("./scenes/ReportCreator/Reports/ReportContainer")
            )}
          />
          <Route>
            <Layout className="layout-container" style={{ minHeight: "100vh" }}>

              <SideMenu counters={this.state} />
              <div className="main-container">
                <AppHeader></AppHeader>
                <Switch>
                  {routes.map((route) => (
                    <ProtectedRoute
                      key={route.path}
                      history={route.history}
                      path={route.path}
                      component={route.component}
                      counters={this.state}
                    />
                  ))}
                </Switch>

                <ToastContainer />
              </div>
            </Layout>
          </Route>
          <Route
            render={() => {
              return <Redirect to={firstRoute}></Redirect>;
            }}
          ></Route>
        </Switch>
      </QueryClientProvider>
    );
  }
}

export default App;
