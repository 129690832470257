import {message} from "antd";
import {autorun, computed, observable} from "mobx";
import {resolve} from "q";
import {DbTables} from "../helpers/db-names/db-tables";
import {SpecialFields} from "../helpers/db-names/special-fields";
import {UserFields} from "../helpers/db-names/user-fields";
import {LoginResponse, SetFieldRequest, SetFieldResponse} from "../helpers/singalr/requests";
import {IRequestError, ResponseWrapper} from "../helpers/singalr/signalr-utils";
import {waitFor} from "../helpers/utils";
import {SaveRowRequest, SaveRowResponse} from "../requests/save-row-request";
import {AccessService} from "./access-service";
import {SignalrService} from "./signalr-service";

export interface IRelation {
    RelatingColumn: string;
    RelatedTable: string;
    RelatedColumn: string;
    Type: number;
}

export interface IColumnInfo {
    Name: string;
    DefaultValue: string;
    Nullable: boolean;
    Type: string;
    Length: number;
    Description: string;
}

export interface ITableSchema {
    IdentityColumn: string;
    Columns: string[];
    ColumnsInfo: {[column: string]: IColumnInfo};
    Relations: IRelation[];
}

export type FieldValue = string | null;
export interface Schema {[table: string]: ITableSchema;}
export interface Row {[field: string]: FieldValue;}
export interface Rows {[id: string]: Row;}
export interface Tables {[table: string]: Rows;}
export interface UniqueColumns {[table: string]: [string];}
export interface RoleColumnAccessData {[table: string]: {[column: string]: string};}
export interface RowData {[key: string]: string;}

export class RowWithData {
    constructor(public Row: Row, public Data: RowData) {
    }
}

export enum SendFieldLocalError {
    RowDoesNotExist = "row-doesnt-exists",
    RowIsNewRow = "row-is-new-row",
    NotConnected = "not-connected",
}

export class RlThisShouldBeNamedSomehow {
    constructor(public RelatingColumnValue: string, public Row: Row, public DataToInclude: RowData) {
    }
}

export class DataService {
    @observable
    public newRowId = "!#newRow!!!#";
    @observable
    public tables: Tables = {};
    @observable
    public uniqueColumns: UniqueColumns = {};

    @observable
    public roleColumnAccessData: RoleColumnAccessData = {};

    @observable
    public schema: Schema = {};

    public saveValueTimeouts: {[fieldPath: string]: number | undefined} = {};
    public saveValuePromiseReject: {[fieldPath: string]: ((reason?: any) => void) | undefined} = {};
    public accessService?: AccessService;

    public savingNewRow: {[table: string]: boolean} = {};

    constructor(protected signalrService: SignalrService) {
        this.signalrService.onFieldUpdate(message => {
            console.log("onFieldUpdate", message);
            const fieldKey = this.getFieldKey(message.table, message.rowId, message.field);
            if(this.saveValueTimeouts[fieldKey] != undefined) {
                return;
            }

            this.setFieldValue(message.table, message.rowId, message.field, message.value);
        });

        this.signalrService.onNewRow(message => {
            console.log("onNewRow", message);
            if(this.tableExists(message.Table)) {
                this.tables[message.Table][message.RowId] = message.Row;
            }
        });

        this.signalrService.onRowsDeleted(message => {
            const table = message.Table;
            message.Ids.forEach((rowId) => {
                if(this.rowExists(table, rowId)) {
                    delete this.tables[table][rowId];
                }
            });
        });

    }

    public processLoginResponse(loginResponse: LoginResponse, accessService: AccessService) {
        this.accessService = accessService;
        this.schema = observable(loginResponse.Schema);
        this.tables = observable(loginResponse.Tables);
        this.uniqueColumns = observable(loginResponse.UniqueColumns);
        this.roleColumnAccessData = loginResponse.RoleColumnAccessData;
    }

    public getRows(table: string) {
        return this.tables[table] || [];
    }

    public getRowsList(table: string, ignoreNewRow?: boolean) {
        const rows = this.getRows(table);
        if(!rows) {
            return undefined;
        }

        const rowsList: Row[] = [];
        for(const id in rows) {
            if(ignoreNewRow && this.isNewRowId(id, table)) {
                continue;
            }
            rowsList.push(rows[id]);
        }
        return rowsList;
    }

    public findRow(table: string, condition: (row: Row) => boolean) {
        const rowsList = this.getRowsList(table);

        if(!rowsList) {
            return undefined;
        }

        return rowsList.find(condition);
    }

    public getRow(table: string, rowId: string) {
        if(!this.tables[table]) {
            return undefined;
        }

        return this.tables[table][rowId];
    }

    public getFieldValue(table: string, rowId: string, field: string) {
        const row = this.getRow(table, rowId);
        if(!row) {
            return null;
        }

        return row[field];
    }

    public getIdColumn(table: string) {
        if(!this.tableInfoExists(table)) {
            return;
        }

        return this.schema[table].IdentityColumn;
    }

    public getRowId(row: Row | undefined, table: string) {
        if(row == undefined) {
            return undefined;
        }

        const idColumn = this.getIdColumn(table);
        if(idColumn == undefined) {
            return undefined;
        }

        return row[idColumn] || undefined;
    }

    public getDisplayColumn(table: string) {
        if(!this.tableInfoExists(table)) {
            return null;
        }

        return this.schema[table].Columns[1];
    }

    public getColumns(table: string) {
        if(!this.schema[table]) {
            return;
        }

        return this.schema[table].Columns;
    }

    public getColumnInfo(table: string, column: string) {
        if(!this.schema[table]) {
            return undefined;
        }
        return this.schema[table].ColumnsInfo[column];
    }

    public getRelations(table: string) {
        if(!this.schema[table]) {
            return [];
        }

        return this.schema[table].Relations;
    }

    public getNewRow(table: string) {
        return this.getRow(table, this.newRowId);
    }

    public createNewRow(table: string, row: Row = {}) {
        if(!this.tableExists(table)) {
            return;
        }

        const idColumn = this.getIdColumn(table);
        if(idColumn == null) {
            return;
        }

        const newRow: Row = row;
        this.schema[table].Columns.filter((column) => !newRow[column]).forEach((column) => {
            const defaultValue = this.schema[table].ColumnsInfo[column].DefaultValue;
            if(defaultValue != null) {
                newRow[column] = defaultValue;
            }
        });
        newRow[idColumn] = this.newRowId;
        // console.log('createNewRow', table, row, this.tables[table]);
        // if (table = DbTables.History) {
        //    let t: { [id: string]: Row } = {};
        //    t[0] = observable(newRow);

        //    for (let i = 0; i < Object.keys(this.tables[table]).length; i++) {
        //        t[i + 1] = this.tables[table][i];
        //    }
        //    this.tables[table] = t;
        // } else {
        console.log("asd");
        this.tables[table][this.newRowId] = observable(newRow);
        //  }

        // this.saveNewRow(table);
        return this.newRowId;
    }

    public getEmptyRow(table: string) {
        const emptyRow: Row = {};
        this.schema[table].Columns.filter((column) => !emptyRow[column]).forEach((column) => emptyRow[column] = this.schema[table].ColumnsInfo[column].DefaultValue);
        return observable(emptyRow);
    }

    public isRowEmptyById(rowId: string, table: string, ignoreFields?: string[] | undefined) {
        const row = this.getRow(table, rowId);

        if(row == undefined) {
            return true;
        }

        return this.isRowEmpty(row, table, ignoreFields);
    }

    public isRowEmpty(row: Row, table: string, ignoreFields?: string[] | undefined) {
        const emptyRow = this.getEmptyRow(table);
        const idColumn = this.getIdColumn(table);
        for(const field in row) {
            if(field == idColumn) {
                continue;
            }

            if(ignoreFields != undefined && ignoreFields.includes(field)) {
                continue;
            }

            let fieldValue = row[field];
            fieldValue = fieldValue != null && fieldValue.length == 0 ? null : fieldValue;

            if(fieldValue === undefined) {
                continue;
            }

            if(fieldValue != emptyRow[field]) {
                console.log(row, emptyRow, field);
                return false;
            }
        }

        console.log(row, emptyRow);
        return true;
    }

    public saveNewRow(table: string, removeOnSuccess = true) {
        return new Promise<ResponseWrapper<SaveRowResponse>>((resolve, reject) => {
            waitFor(() => this.savingNewRow[table] !== true, () => {
                const newRowId = this.newRowId;
                if(!this.rowExists(table, newRowId)) {
                    return;
                }

                const idColumn = this.getIdColumn(table);
                if(idColumn == null) {
                    return;
                }

                const newRow = {...this.tables[table][newRowId], [idColumn]: null};
                this.savingNewRow[table] = true;
                console.log("this.accessService", this.accessService);
                if(this.accessService) {
                    new SaveRowRequest(this.accessService, table, newRow).send().then((responseWrapper) => {
                        if(responseWrapper.Error) {
                            console.log("new row responseWrapper.Error", responseWrapper.Error);
                            console.log(responseWrapper.Error);
                        }

                        if(removeOnSuccess && responseWrapper.Response) {
                            delete this.tables[table][newRowId];
                        }

                        if(table == "Report") {
                            this.tables[table][(responseWrapper.Response as any).Id] = observable(responseWrapper.Response as Row);
                        }

                        console.log("new row", responseWrapper);
                        this.savingNewRow[table] = false;
                        return responseWrapper;
                    }).then(resolve, reject).catch(reject);
                }
            });
        });
    }

    public isNewRow(row: Row, table: string) {
        const id = this.getRowId(row, table);
        if(id == undefined) {
            return false;
        }
        return this.isNewRowId(id, table);
    }

    public isNewRowId(rowId: FieldValue, table: string) {
        return (rowId == this.newRowId);
    }

    public deleteNewRow(table: string) {
        if(!this.rowExists(table, this.newRowId)) {
            return;
        }

        delete this.tables[table][this.newRowId];
    }

    public getFieldSearchValue(table: string, rowId: string, field: string) {
        if(!this.rowExists(table, rowId) || !this.tableInfoExists(table)) {
            return "";
        }

        const fieldValue = this.tables[table][rowId][field];
        const fieldRelation = Object.values(this.schema[table].Relations).find((rel) => rel.Type == 1 && rel.RelatingColumn == field);
        if(fieldRelation != null) {
            const relatedRow = Object.values(this.tables[fieldRelation.RelatedTable]).find((row) => row[fieldRelation.RelatedColumn] == fieldValue);
            if(relatedRow != null) {
                const displayColumn = this.getDisplayColumn(fieldRelation.RelatedTable);
                if(displayColumn != null) {
                    return relatedRow[displayColumn];
                }
            }
        }
        return fieldValue;
    }

    public setFieldValue(table: string, rowId: string, field: string, newValue: FieldValue) {
        if(!this.rowExists(table, rowId)) {
            return false;
        }

        this.tables[table][rowId][field] = newValue;
        return true;
    }

    public setAndSendFieldValue(table: string, rowId: string, field: string, newValue: FieldValue, cooldownTime?: number) {
        console.log("setAndSendFieldValue", table, rowId, field, newValue);
        return new Promise<ResponseWrapper<SetFieldResponse>>((resolve, reject) => {
            console.log("a", table, rowId, field, newValue);
            if(this.accessService && this.accessService.signalrService && !this.accessService.signalrService.connectionOpen) {
                resolve({
                    RequestId: "request-not-sent",
                    Error: {Message: "Not connected to server", Code: SendFieldLocalError.NotConnected},
                });
                return;
            }

            console.log("b", table, rowId, field, newValue);
            if(!this.setFieldValue(table, rowId, field, newValue)) {
                resolve({
                    RequestId: "request-not-sent",
                    Error: {Message: "Row does not exists", Code: SendFieldLocalError.RowDoesNotExist},
                });
                return;
            }

            console.log("c", table, rowId, field, newValue);
            if(this.isNewRowId(rowId, table)) {
                resolve({
                    RequestId: "request-not-sent",
                    Error: {
                        Message: "Row is new row, fields should be updated only after saving new row",
                        Code: SendFieldLocalError.RowIsNewRow,
                    },
                });
                return;
            }

            console.log("d", table, rowId, field, newValue);
            const fieldKey = this.getFieldKey(table, rowId, field);
            if(this.saveValueTimeouts[fieldKey] != undefined) {
                clearTimeout(this.saveValueTimeouts[fieldKey]);
                delete this.saveValueTimeouts[fieldKey];
            }

            this.saveValueTimeouts[fieldKey] = setTimeout(() => {
                if(this.accessService) {
                    const result = new SetFieldRequest(this.accessService, table, rowId, field, newValue).send();
                    result.then((responseWrapper) => {
                        delete this.saveValueTimeouts[fieldKey];
                        resolve(responseWrapper);
                        return responseWrapper;
                    });
                }
            }, cooldownTime || 500) as any as number;
        });
    }

    public tableInfoExists(table: string) {
        if(this.schema == undefined || this.schema[table] == undefined) {
            return false;
        }
        return true;
    }

    public columnInfoExists(table: string, column: string) {
        if(!this.tableInfoExists(table) || this.schema[table].ColumnsInfo[column] == undefined) {
            return false;
        }
        return true;
    }

    public rowExists(table: string, rowId: string) {
        if(!this.tableExists(table) || this.tables[table][rowId] == undefined) {
            return false;
        }

        return true;
    }

    public tableExists(table: string) {
        if(this.tables == undefined || this.tables[table] == undefined) {
            return false;
        }

        return true;
    }

    public emptyRowsAllowed(table: string) {
        if(!this.tableInfoExists(table)) {
            return false;
        }

        const idColumn = this.getIdColumn(table);
        if(idColumn == null) {
            return false;
        }

        return Object.values(this.schema[table].ColumnsInfo).filter((ci) => ci.Name != idColumn).every((ci) => ci.Nullable == true || ci.DefaultValue != null);
    }

    public getPathRelatedRows(row: Row, tableName: string, includePath: string[]): Row[] {
        if(includePath == null || includePath.length < 1) {
            return [];
        }

        const tableToInclude = includePath[0];

        if(includePath.length == 1) {
            return this.getRelatedRows(row, tableName, tableToInclude);
        }

        const restOfPath = includePath.slice(1);
        const list: Row[] = [];
        const relatedRows = this.getRelatedRows(row, tableName, tableToInclude);
        if(relatedRows == null) {
            return [];
        }

        relatedRows.forEach((relatedRow) => {
            const pathRelatedRows = this.getPathRelatedRows(relatedRow, tableToInclude, restOfPath);
            if(pathRelatedRows != null) {
                list.push(...pathRelatedRows);
            }
        });
        return list;
    }

    public hasActiveField(table: string) {
        return this.schema[table].ColumnsInfo[SpecialFields.Active] !== undefined;
    }

    public getSpecialColumns(table: string) {
        return [this.getIdColumn(table), SpecialFields.Active];
    }

    public getRelatedRows(row: Row, table: string, tableToInclude: string, ignoreNewRow?: boolean) {
        const tableToIncludeRows = this.getRowsList(tableToInclude, ignoreNewRow);
        if(!tableToIncludeRows || tableToIncludeRows.length == 0) {
            return [];
        }

        const relations = this.schema[table].Relations;

        const relation = relations.find((r) => r.RelatedTable == tableToInclude);

        if(relation == null) {
            return [];
        }

        const relatingFieldValue = row[relation.RelatingColumn];

        if(relatingFieldValue == null) {
            return [];
        }

        const relatedRows = tableToIncludeRows.filter(
            (r) => r[relation.RelatedColumn] == relatingFieldValue,
        );

        return relatedRows;
    }

    public getRelatedRowsMany(rows: Row[], table: string, tableToInclude: string, ignoreNewRow?: boolean) {
        const tableToIncludeRows = this.getRowsList(tableToInclude, ignoreNewRow);
        if(!tableToIncludeRows || tableToIncludeRows.length == 0) {
            return [];
        }

        const relations = this.schema[table].Relations;

        const relation = relations.find((r) => r.RelatedTable == tableToInclude);

        if(relation == null) {
            return [];
        }

        const relatingFieldValues = rows.map((row) => row[relation.RelatingColumn] as string).filter((fieldValue) => fieldValue != null);

        const relatedRows = tableToIncludeRows.filter((r) => relatingFieldValues.includes(r[relation.RelatedColumn] as string));

        return relatedRows;
    }

    public getRelatedRowsById(rowId: string, table: string, tableToInclude: string, ignoreNewRow?: boolean) {
        const row = this.tables[table][rowId];
        if(!row) {
            return [];
        }

        return this.getRelatedRows(row, table, tableToInclude, ignoreNewRow);
    }

    public getRelatedRowById(rowId: string, table: string, tableToInclude: string, ignoreNewRow?: boolean) {
        return this.getRelatedRowsById(rowId, table, tableToInclude, ignoreNewRow)[0] || undefined;
    }

    public getRelatedRowsWithData(rows: Row[], tableName: string, tableToInclude: string, dataToInclude: RowData[]) {
        const rowIds = rows.map((row) => this.getRowId(row, tableName) as string).filter((rowId) => rowId != undefined);
        return this.getRelatedRowsWithDataById(rowIds, tableName, tableToInclude, dataToInclude);
    }

    public getRelatedRowsWithDataById(rowIds: string[], tableName: string, tableToInclude: string, dataToInclude: RowData[]) {
        const relations = this.getRelations(tableName);

        if(!relations) {
            return;
        }

        const relation = relations.find((r) => r.RelatedTable == tableToInclude);

        if(!relation) {
            return;
        }

        const relatingColumn = relation.RelatingColumn;
        const relatedColumn = relation.RelatedColumn;

        const rls = rowIds.map((rowId, index) => {
            const rowDataToInclude = dataToInclude[index];
            const row = this.getRow(tableName, rowId) as Row;
            return new RlThisShouldBeNamedSomehow(row[relatingColumn] as string, row, rowDataToInclude);
        });

        const relatingRows: {[relatingColumnValue: string]: RlThisShouldBeNamedSomehow} = {};

        rls.forEach((rl) => {
            relatingRows[rl.RelatingColumnValue] = rl;
        });

        const relatedTableRows = this.getRowsList(tableToInclude);

        if(!relatedTableRows) {
            return;
        }

        return relatedTableRows.map((relatedTableRow) => {
            const relatedTableValue = relatedTableRow[relatedColumn];
            if(relatedTableValue == null) {
                return;
            }
            const relatingRow = relatingRows[relatedTableValue];
            if(relatingRow == null) {
                return;
            }
            return new RowWithData(relatedTableRow, relatingRow.DataToInclude);
        }).filter((rwd) => !!rwd);
    }

    public getAvailableTables() {
        return Object.keys(this.tables || {});
    }

    private getFieldKey(table: string, rowId: string, field: string) {
        return table + "/" + rowId + "/" + field;
    }
}

