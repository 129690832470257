import { StdRequest } from "../helpers/singalr/signalr-utils";
import { SignalrService } from "../services/signalr-service";
import { Row } from "../services/data-service";
import { AccessService } from "../services/access-service";

export type SaveRowResponse = Row;
export class SaveRowRequest extends StdRequest<SaveRowResponse>
{
    constructor(accessService: AccessService, public Table: string, public Row: Row)
    {
        super(accessService, 'SaveRowRequest');
    }
}