//import { ClientBase } from "./BaseClient";

import { AxiosError } from "axios";
import { ApiException } from "./ToolApiClient";



export class ClientBase {
    protected transformOptions(options: any) {
        
        let token = localStorage.getItem("token");

        if(token) {
            options.headers['Authorization'] = `Bearer ${token}`;
        }


        //    var old_headers: HttpHeaders = <HttpHeaders>options.headers;
        //    var new_headers: { [key: string]: string } = {};
        //    old_headers.keys().forEach(k => {
        //        new_headers[k] = old_headers.get(k);
        //    });
        //    if (token && token != '') {
        //        new_headers["Authorization"] = "Bearer " + token;
        //    }

        //    Object.keys(default_headers).forEach(k => {
        //        new_headers[k] = default_headers[k];
        //    });

        //    options.headers = new HttpHeaders(new_headers);

        //}
        return Promise.resolve(options);
    }

    protected transformResult(url: string, response: Response, processor: (response: Response) => any) {
        return processor(response);
    }

    protected getBaseUrl(baseUrl: string) {
        //var devUrl = "http://localhost:3000";
        console.log(process.env.NODE_ENV);
        if (process.env.NODE_ENV === 'development')
            return "https://tool.tradinghosting.com";
        //    return "http://localhost:14067";
        return "https://tool.tradinghosting.com";;;
    }

    public throwException(message: string, status: number, response: string, headers: { [key: string]: any; }, result?: any): any {
        if (result !== null && result !== undefined)
            throw result;
        else
            throw new ApiException(message, status, response, headers, null);
    }

    public isAxiosError(obj: any | undefined): obj is AxiosError {
        return obj && obj.isAxiosError === true;
    }
}
