import { reaction } from "mobx";
import { ICellRendererParams } from "ag-grid-community";

declare global {
  interface HTMLElement {
    setTagName: (tag: string) => void;
  }
  interface Window {
    any: any;
  }
}

Object.defineProperty(Window.prototype, "any", {
  get: function () {
    return this;
  },
  set: function () {},
  enumerable: true,
  configurable: true,
});

HTMLElement.prototype.setTagName = function (tag: string) {
  const original = this;
  const replacement = document.createElement(tag);
  for (let i = 0, l = original.attributes.length; i < l; ++i) {
    const attribute = original.attributes.item(i);
    if (attribute == undefined) continue;
    const nodeName = attribute.nodeName;
    const nodeValue = attribute.nodeValue;

    if (nodeValue != undefined) replacement.setAttribute(nodeName, nodeValue);
  }
  replacement.innerHTML = original.innerHTML;

  const parentNode = original.parentNode;
  if (parentNode != undefined) parentNode.replaceChild(replacement, original);
};

Object.defineProperty(window, "div", {
  get: function () {
    if (window.any._div == undefined) {
      window.any._div = document.createElement("div");
      document.body.appendChild(window.any._div);
    }
    return window.any._div;
  },
  enumerable: true,
  configurable: true,
});

export function isElementVisible(elem: any) {
  const style = getComputedStyle(elem);
  if (style.display === "none") return false;
  if (style.visibility !== "visible") return false;
  if ((style.opacity as any) == 0) return false;
  if (
    elem.offsetWidth +
      elem.offsetHeight +
      elem.getBoundingClientRect().height +
      elem.getBoundingClientRect().width ===
    0
  ) {
    return false;
  }
  let elementPoints: any = {
    center: {
      x: elem.getBoundingClientRect().left + elem.offsetWidth / 2,
      y: elem.getBoundingClientRect().top + elem.offsetHeight / 2,
    },
    "top-left": {
      x: elem.getBoundingClientRect().left,
      y: elem.getBoundingClientRect().top,
    },
    "top-right": {
      x: elem.getBoundingClientRect().right,
      y: elem.getBoundingClientRect().top,
    },
    "bottom-left": {
      x: elem.getBoundingClientRect().left,
      y: elem.getBoundingClientRect().bottom,
    },
    "bottom-right": {
      x: elem.getBoundingClientRect().right,
      y: elem.getBoundingClientRect().bottom,
    },
  };

  for (let index in elementPoints) {
    var point = elementPoints[index];
    if (point.x < 0) return false;
    if (point.x > (document.documentElement.clientWidth || window.innerWidth))
      return false;
    if (point.y < 0) return false;
    if (point.y > (document.documentElement.clientHeight || window.innerHeight))
      return false;
    let pointContainer = document.elementFromPoint(point.x, point.y) as any;
    if (pointContainer !== null) {
      do {
        if (pointContainer === elem) return true;
      } while ((pointContainer = pointContainer.parentNode));
    }
  }
  return false;
}

export const ModalsSize = {
  width: "calc(100vw - 80px)",
  height: "calc(100vh - 240px)",
};
export function waitFor(
  condition: () => boolean,
  callback: () => any,
  interval: number = 100,
  timeLimit?: number,
  iteration: number = 1
) {
  if (timeLimit != undefined && timeLimit < interval * iteration) return;

  if (!condition()) {
    window.setTimeout(
      waitFor.bind(null, condition, callback, interval),
      interval,
      timeLimit,
      iteration + 1
    ); /* this checks the flag every x milliseconds*/
  } else {
    callback();
  }
}

export class Box<T> {
  value!: T;
}

export const bindInput = (
  get: () => string | undefined,
  set: (v: string | undefined) => void
) => ({
  value: get(),
  onChange: (e: any) => {
    const newValue = e.target.value;
    if (get() != newValue) set(newValue);
  },
});

export const bindCheckbox = (
  get: () => boolean,
  set: (v: boolean) => void
) => ({
  checked: get(),
  onChange: (e: any) => {
    const newValue = e.target.checked;
    if (get() != newValue) set(newValue);
  },
});

export type ClassObj = { [className: string]: boolean | undefined };

export const ngClass = (classObj: ClassObj) => {
  const classesToApply: string[] = [];
  for (let klass in classObj) {
    if (classObj[klass] == true) classesToApply.push(klass);
  }

  return { className: classesToApply.join(" ") };
};

export const reactionWithOldValue = <T>(
  expression: () => T,
  effect: (newValue: T, oldValue?: T) => void
) => {
  let oldValue: T;
  return reaction(expression, (v) => {
    effect(v, oldValue);
    oldValue = v;
  });
};

let tag = document.createElement("div");
tag.style.position = "absolute";
tag.style.left = "-999em";
tag.style.whiteSpace = "nowrap";
document.body.appendChild(tag);

export function getTextWidth(text: string, font?: string) {
  if (!!font) tag.style.font = font;
  tag.innerHTML = text;

  var result = tag.clientWidth;

  return result;
}

export const encodeURI2 = function (str: string | undefined | null) {
  if (str == undefined) return undefined;

  return encodeURI(str);
};

export const decodeURI2 = function (str: string | undefined | null) {
  if (str == undefined) return undefined;

  return decodeURI(str);
};

export function decodeHTMLEntities(text: string) {
  var entities = [
    ["amp", "&"],
    ["apos", "'"],
    ["#x27", "'"],
    ["#x2F", "/"],
    ["#39", "'"],
    ["#47", "/"],
    ["lt", "<"],
    ["gt", ">"],
    ["nbsp", " "],
    ["quot", '"'],
  ];

  for (var i = 0, max = entities.length; i < max; ++i)
    text = text.replace(
      new RegExp("&" + entities[i][0] + ";", "g"),
      entities[i][1]
    );

  return text;
}

export const ToTitleCase = (str) => {
  if (str)
    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
};

export const True = true;
export const False = false;

export const onEditRow = (props: ICellRendererParams) => {
  removeEditRowClass();

  if (props.eGridCell.parentElement) {
    props.eGridCell.parentElement.classList.add("ag-edit-row");

    const dataContainer = document.getElementsByClassName(
      "ag-center-cols-container"
    );

    if (dataContainer.length > 0) {
      const rowElement = dataContainer[0].querySelectorAll(
        `[aria-row-index="${props.rowIndex}"]`
      );

      if (rowElement.length > 0 && rowElement[0].classList) {
        rowElement[0].classList.add("ag-edit-row");
      }
    }
  }
};

export const removeEditRowClass = () => {
  const editRows = [...document.getElementsByClassName("ag-edit-row")];
  const editRowsCount = editRows.length;
  if (editRowsCount > 0)
    for (let i = 0; i < editRowsCount; i++)
      editRows[i].classList.remove("ag-edit-row");
};

export const onGridCellClicked = () => {
  if (
    document.activeElement &&
    !document.activeElement.classList.contains("GridCellClicked")
  ) {
    document.activeElement.classList.add("GridCellClicked");
    const inputElement: any = document.activeElement;
    if (!!inputElement.value)
      inputElement.setSelectionRange(
        inputElement.value.length,
        inputElement.value.length
      );
  }
};

const fallbackCopyTextToClipboard = (text: string, callback?: () => void) => {
  var textArea = document.createElement("textarea");
  textArea.value = text;

  // Avoid scrolling to bottom
  textArea.style.top = "0";
  textArea.style.left = "0";
  textArea.style.position = "fixed";

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  document.execCommand("copy");
  document.body.removeChild(textArea);

  callback && callback();
};

export const copyTextToClipboard = (text: string, callback?: () => void) => {
  if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(text, callback);
    return;
  }
  navigator.clipboard.writeText(text).then(
    () => {
      callback && callback();
    },
    function (err) {}
  );
};

/**
 * get on screen selected text
 * @returns text
 */
const getSelectionText = () => {
  var text = "";
  const appdocument: any = document;
  if (window.getSelection) {
    const windowSelection = window.getSelection();
    if (windowSelection) text = windowSelection.toString();
  } else if (appdocument.selection && appdocument.selection.type != "Control") {
    text = appdocument.selection.createRange().text;
  }
  return text;
};

/**
 * Add Event listener for keydown
 */
document.addEventListener("keydown", (event) => {
  handleKeyDown(event);
});

/**
 * handle keydown event and copy inner text of selected AgGrid cell's text
 * @param event
 */
const handleKeyDown = (event) => {
  let charCode = String.fromCharCode(event.which).toLowerCase();
  if ((event.ctrlKey || event.metaKey) && charCode === "c") {
    const selectedText = getSelectionText();
    if (selectedText.length == 0) {
      const focusedCells = document.getElementsByClassName("ag-cell-focus");
      const rangeSelectedCells = document.getElementsByClassName(
        "ag-cell-range-selected-1"
      ).length;
      if (focusedCells.length == 1 && rangeSelectedCells <= 1)
        if (focusedCells[0].textContent)
          copyTextToClipboard(focusedCells[0].textContent);
    }
  }
};

export enum AssignToCampaignType {
  Title,
  Header,
  Body,
}

export const AssignToCampaignTypeOptions = {
  "By Title": 0,
  "By Header": 1,
  "By Body": 2,
};

export function GetAssignToCampaignTypes() {
  var data: any = [];
  for (var item in AssignToCampaignType) {
    if (isNaN(Number(item))) {
      data.push({ name: item, value: AssignToCampaignType[item] });
    }
  }
  return data;
}

export const settingModalTitle = {
  AddSetting: "Add Settings",
  EditSetting: "Edit Settings",
};

export const ScannModalTitle = {
  AddScannSender: "Add Scanned sender",
  EditScannEmail: "Edit Scanned emails",
};

export function onTooltipHover(event, text) {
  if (event.target.tagName == "SPAN" || event.target.tagName == "svg") {
    const elements = document.getElementsByClassName("tooltiptext");
    const header = event.target.parentElement.parentElement;
    const headerPosition = header.getBoundingClientRect();
    if (elements.length == 0) {
      const div: any = document.createElement("div");
      div.classList.add("tooltiptext");
      div.style.top = headerPosition.y + headerPosition.height + "px";
      div.style.left = headerPosition.x - headerPosition.width / 2 + "px";
      div.innerHTML = text;
      div.async = true;
      document.body.appendChild(div);
    }
  }
}

export function onTooltipHoverLeave() {
  removeElementsByClass("tooltiptext");
}

export function removeElementsByClass(className) {
  const elements = document.getElementsByClassName(className);
  while (elements.length > 0 && elements[0].parentNode) {
    elements[0].parentNode.removeChild(elements[0]);
  }
}

export const frequencies = [
  { value: "2 weeks", label: "2 weeks" },
  { value: "4 weeks", label: "4 weeks" },
  { value: "6 weeks", label: "6 weeks" },
  { value: "8 weeks", label: "8 weeks" },
];

export function uniqueBy<T>(arr: T[], keyFn: (item: T) => string): T[] {
  const seen = new Set<string>();
  return arr.filter((item) => {
    const key = keyFn(item);
    if (seen.has(key)) {
      return false;
    } else {
      seen.add(key);
      return true;
    }
  });
}
