import { LoginRequest, LoginResponse } from "../helpers/singalr/requests";
import { SignalrService } from "./signalr-service";
import { DataService, Row, FieldValue } from "./data-service";
import { observable, computed, reaction } from "mobx";
import { IRequestError } from "../helpers/singalr/signalr-utils";
import { SessionFields } from "../helpers/db-names/session-fields";
import { DbTables } from "../helpers/db-names/db-tables";
import { UserFields } from "../helpers/db-names/user-fields";
import { GoogleAccountFields } from "../helpers/db-names/google-account-fields";
import { CampaignFields } from "../helpers/db-names/campaign-fields";
import { UserCampaignFields } from "../helpers/db-names/user-campaign-fields";
import {UserRoleType} from "../helpers/db-names/user-role-type";
import moment from "moment";

export class AccessLevels
{
    static Read = "1";
    static Write = "2";
    static Manager = "3";

    static Compare(accessLevel: FieldValue, minRequiredAccessLevel: FieldValue)
    {
        if (accessLevel == undefined || minRequiredAccessLevel == undefined)
            return false;

        const intAccessLevel = parseInt(accessLevel);
        if(isNaN(intAccessLevel))
            return false;
            
        const intMinRequiredAccessLevel = parseInt(minRequiredAccessLevel);
        if(isNaN(intMinRequiredAccessLevel))
            return false;

        return intAccessLevel >= intMinRequiredAccessLevel;
    }
}

export class AccessService
{
    @observable
    loginResponse?: LoginResponse;

    @observable
    username?: string;

    get sessionKey()
    {
        return window.localStorage.getItem('sessionKey');
    }

    set sessionKey(value)
    {
        if (value == null)
        {
            window.localStorage.removeItem('sessionKey')
        }
        else
        {
            window.localStorage.setItem('sessionKey', value);
        }
    }

    @computed
    get currentUserId()
    {
        return this.loginResponse && this.loginResponse.UserId || undefined;
    }

    @computed
    get isAdmin()
    {
        if(this.currentUserId == undefined)
            return false;

        const currentUserRow = this.dataService.getRow(DbTables.User, this.currentUserId);
    
        if(currentUserRow == undefined)
            return false;
        
        return currentUserRow[UserFields.Role] == UserRoleType.Admin;
    }
    
    @computed
    get isSpecialistOrContact()
    {
        if(this.currentUserId == undefined)
            return false;

        const currentUserRow = this.dataService.getRow(DbTables.User, this.currentUserId);
    
        if(currentUserRow == undefined)
            return false;
        
        return currentUserRow[UserFields.Role] == UserRoleType.Specialist || currentUserRow[UserFields.Role] == UserRoleType.SpecialistContact;
    }
    
    @computed
    get isCampaignManager()
    {
        if(this.currentUserId == undefined)
            return false;

        const currentUserRow = this.dataService.getRow(DbTables.User, this.currentUserId);
    
        if(currentUserRow == undefined)
            return false;
        
        return this.isUserInRole("CampaignManager")
    }
    
    @computed
    get isDeveloper()
    {
        if(this.currentUserId == undefined)
            return false;

        const currentUserRow = this.dataService.getRow(DbTables.User, this.currentUserId);
    
        if(currentUserRow == undefined)
            return false;
        
        return this.isUserInRole(UserRoleType.Developer) || this.isUserInRole(UserRoleType.DeveloperPro)
    }
    @computed
    get isDeveloperPro()
    {
        if(this.currentUserId == undefined)
            return false;

        const currentUserRow = this.dataService.getRow(DbTables.User, this.currentUserId);
    
        if(currentUserRow == undefined)
            return false;
        
        return this.isUserInRole(UserRoleType.DeveloperPro)
    }

    @computed
    get isLoggedIn()
    {
        return this.loginResponse != undefined;
    }

    isUserInRole = (role: string): boolean | undefined => {
        //return this.loginResponse && this.loginResponse.Roles.indexOf(role) > -1;
        return this.loginResponse && this.loginResponse.Role == role;
    };


    constructor(public signalrService: SignalrService, protected dataService: DataService)
    {
        reaction(() => this.signalrService.connectionOpen, 
            connectionOpen =>
            {
                if(connectionOpen)
                {
                    //this.sessionKey
                    //registersignalrconnection
                }
            }
        )
    }

    registerSignalrConnection(sessionKey: string)
    {

    }

    canEditRowById(table: string, rowId: string, field?: string): boolean
    {
        return this.canEditRow(table, this.dataService.getRow(table, rowId), field);
    }

    canEditRow(table: string, row?: Row, field?: string): boolean
    {
        const userId = this.currentUserId;
        if(userId == undefined)
            return false;

        if(this.isAdmin)
            return true;
        
        // if (!row) {
        //     return false;
        // }
        
        if (field!=null && this.dataService.roleColumnAccessData[table]!=null) {
            let access=this.dataService.roleColumnAccessData[table][field] || this.dataService.roleColumnAccessData[table]['*'];
            if (access=='READONLY') {
                return false;                
            }
        }
        
        const tableRelations = this.dataService.getRelations(table);

        if(!tableRelations)
            return false;
        
        return true;
        
    }

    logout()
    {
        this.loginResponse = undefined;
        this.sessionKey = null;
        (window as any).location.reload();
    }

    login(username?: string, password?: string)
    {
        return new LoginRequest(this, username, password).send().then(responseWrapper =>
        {
            const response = responseWrapper.Response;
            if(!!response)
            {
                this.loginResponse = observable(response);
                this.username = response.Username;
                this.sessionKey = response.SessionKey;
                this.dataService.processLoginResponse(response, this);
                this.setSessionExpireTimeout(response.SessionExpires);
            }
            return responseWrapper;
        });
    }

    setSessionExpireTimeout(sessionExpiresStr: FieldValue)
    {
        if(sessionExpiresStr == null)
            return;
                
        const now = moment.utc();
        const sessionExpires = moment.utc(sessionExpiresStr)
        const expiriationTimeLeft = sessionExpires.diff(now);
        setTimeout(() =>
        {
            window.any.location.reload();
        }, expiriationTimeLeft);
    }
}